<template>
  <div>
    <public-header />
    <div class="wrapper smsServicePageWrapper">
      <div class="page-header page-header-small">
        <div
          class="page-header-image"
          data-parallax="true"
        />
        <div class="content-center mt-4">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <img
                  src="https://www.clicksend.com/img/ic-sms_product_page.svg"
                  alt="alt"
                >
                <h1>Business SMS Gateway</h1>
                <p>Send Marketing SMS Online Through A Web Based SMS Portal or Transactional SMS via an SMS Gateway API.</p>
                <button
                  v-if="isUserLoggedIn"
                  class="getSTartedBtn"
                  @click="$router.push('/pages/dashboard')"
                >
                  Goto dashboard
                </button>
                <button
                  v-if="!isUserLoggedIn"
                  class="getSTartedBtn"
                  @click="$router.push('/register')"
                >
                  Get Started Now!
                </button>
              </div>
              <div class="col-md-6" />
            </div>
          </div>
        </div>
        <img
          class="landingBackgroundGraphic"
          src="https://www.clicksend.com/img/img-sms_header.svg"
          alt="alt"
        >
      </div>
      <div class="container overviewSectionWrapper">
        <div class="row">
          <div class="col-12 overviewSectionHeaderContents">
            <h1>Track, Receive, & Send SMS Online Messages Worldwide</h1>
            <p class="sub1">
              Business messaging made easy. Send thousands of text messages anywhere in the world with the ClickSend Web to SMS service. No SMS software downloads required; sign up and be sending from dashboard or API within minutes.
            </p>
            <p class="sub2">
              Access all of these features on the ClickSend Business SMS Gateway and API.
            </p>
          </div>
          <div class="col-md-6 overViewCardWrapper">
            <div class="overViewCard">
              <img
                src="https://www.clicksend.com/img/ic-shared_laptop_white.svg"
                alt="alt"
              >
              <div class="textSection">
                <h4>Online SMS Gateway</h4>
                <p>ClickSend’s online short message service (SMS) gateway allows you to send and receive SMS communications from any web enabled computer. Send messages online to individuals or a large number of contacts through our online SMS service, in a few simple steps.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 overViewCardWrapper">
            <div class="overViewCard">
              <img
                src="https://www.clicksend.com/img/ic-email_to_sms.svg"
                alt="alt"
              >
              <div class="textSection">
                <h4>Email to SMS</h4>
                <p>ClickSend’s email to SMS gateway is ideal for those who use email as their primary communication channel, offering seamless integration with almost any email software. Using our Email to SMS portal is as simple as sending a regular email.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 overViewCardWrapper">
            <div class="overViewCard">
              <img
                src="https://www.clicksend.com/img/ic-shared_sms_gateway_api.svg"
                alt="alt"
              >
              <div class="textSection">
                <h4>SMS Gateway API</h4>
                <p>SMS Gateway API enables integration directly with business applications, websites and software. Our flexible and powerful set of API’s enable developers to add the ability for their applications to send and receive SMS. ClickSend offers SMS solutions for your business needs.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 overViewCardWrapper">
            <div class="overViewCard">
              <img
                src="https://www.clicksend.com/img/ic-shared_microsoft_outlook_sms.svg"
                alt="alt"
              >
              <div class="textSection">
                <h4>Online SMS Gateway</h4>
                <p>ClickSend’s online short message service (SMS) gateway allows you to send and receive SMS communications from any web enabled computer. Send messages online to individuals or a large number of contacts through our online SMS service, in a few simple steps.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 overViewCardWrapper">
            <div class="overViewCard">
              <img
                src="https://www.clicksend.com/img/ic-shared_bulk_sms_marketing.svg"
                alt="alt"
              >
              <div class="textSection">
                <h4>Bulk SMS Marketing </h4>
                <p>Bulk SMS can be sent using our Online SMS service, Email to SMS service or through your existing application using our secure API. Simply upload a list of recipients and our online SMS service will do the rest. We automatically handle opt-outs and replies are free.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 overViewCardWrapper">
            <div class="overViewCard">
              <img
                src="https://www.clicksend.com/img/ic-shared_smpp_api.svg"
                alt="alt"
              >
              <div class="textSection">
                <h4>SMPP API</h4>
                <p>Send bulk SMS through our high performance SMPP gateway. If you’re looking for a high throughput SMS aggregator, this is the product you’re looking for.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="smsGatewayWrapper">
        <div class="container">
          <div class="row">
            <div class="col-12 smsGatewayWrapperInnerWrapper">
              <h1>What is an SMS Gateway?</h1>
              <p>An SMS Gateway allows a computer to send and receive SMS messages to and from a mobile device. Via a telecommunications network. An SMS Gateway, at its simplest, is a website that allows you to send text messages to contact lists via a telecommunications provider. The SMS Gateway can then receive any replies. There are two main ways computers interact with SMS Gateways. Through an Online SMS Gateway via a website/dashboard or via an SMS Gateway API.</p>
            </div>
            <div class="col-12 smsGatewayInfo">
              <img
                src="https://www.clicksend.com/img/img-shared_sms_gateway_phone.svg"
                alt="alt"
              >
              <div class="textSection">
                <div class="descWrapper">
                  <h3>
                    Marketing SMS
                  </h3>
                  <p>SMS Marketing is when businesses spread permission-based (Opt in) promotional messages by text. Examples of SMS Marketing are a restaurant promoting 50% off main meals on a specific night. A fashion store inviting you to a VIP night, a gym letting you know a new class is available. Most SMS Marketing is done via dashboard in the form of bulk SMS. All SMS Marketing messages must have an Opt-out function at the end of the message. Generally in the form of ‘Reply STOP’.</p>
                </div>
                <div class="descWrapper">
                  <h3>
                    Transactional SMS
                  </h3>
                  <p>Transactional SMS is generally all business text messages that aren’t marketing or promotional.They are functional or informational text messages. About a product or service that a customer has engaged with. Examples of Transactional SMS are a dentist surgery reminding you of an appointment. An auto centre reminding you your car is due for a service. A delivery service saying a parcel is out for delivery. Transactional SMS messages can be consistent in their content and done at scale. So businesses will generally use an SMS Gateway API to automate the process. Transactional SMS messages are not required to have an opt-out message.</p>
                </div>
                <button
                  v-if="isUserLoggedIn"
                  class="getSTartedBtn"
                  @click="$router.push('/pages/dashboard')"
                >
                  Goto dashboard
                </button>
                <button
                  v-if="!isUserLoggedIn"
                  class="getSTartedBtn"
                  @click="$router.push('/register')"
                >
                  Get Started Now!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container whyUseWrapper">
        <div class="row">
          <div class="col-12">
            <h1>Why Use the ClickSend SMS Gateway?</h1>
          </div>
          <div class="col-md-6 useSub1">
            <h3>Your messages get delivered</h3>
            <p>Whether it’s a bulk SMS marketing campaign or transactional SMS at scale via our SMS Gateway API you need to be confident your messages are being delivered. ClickSend provides that confidence by the use of direct routes and 100% Uptime SLA Guarantee.</p>
          </div>
          <div class="col-md-6 useSub1">
            <h3>It’s so easy to send your messages</h3>
            <p>The Online SMS Gateway dashboard is super simple to use. Whether a sole trader or a CMO, you will have campaigns launched in minutes. And the SMS Gateway API is loved by developers around the world for its documentation, client libraries and ease of use.</p>
          </div>
          <div class="col-md-4 useSub2">
            <img
              src="https://www.clicksend.com/img/img-shared_sms_keywords.svg"
              alt="alt"
            >
            <h3>SMS Keywords</h3>
            <p>Automate your SMS communications without losing personalization. Use our autoresponder service to build an interactive experience, picking up on SMS keywords and responding automatically.</p>
          </div>
          <div class="col-md-4 useSub2">
            <img
              src="https://www.clicksend.com/img/img-shared_measure_success.svg"
              alt="alt"
            >
            <h3>Measure Success</h3>
            <p>Use the reporting tools to track how ClickSend is generating real business value. Monitor delivery performance and response rates via our super simple, yet advanced reporting and analytics</p>
          </div>
          <div class="col-md-4 useSub2">
            <img
              src="https://www.clicksend.com/img/img-shared_custom_integration.svg"
              alt="alt"
            >
            <h3>Custom Integration</h3>
            <p>ClickSend can be integrated into pretty much any business system. Build powerful apps using the fastest and most reliable cloud communications APIs. We can’t wait to see what you build!</p>
          </div>
        </div>
      </div>
      <div class="standerdFeatureWrapper">
        <div class="standerdFeatureHeaderImageWrapper">
          <img
            src="https://www.clicksend.com/img/ic-shared-sms2_page.svg"
            alt="alt"
          >
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h4>Standard Features Included</h4>
              <div class="container standerdFeatureInnerWrapper">
                <div class="row">
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-people.svg"
                      alt="alt"
                    >
                    <p>Reliable – 100% uptime SLA guarantee</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-lifesaver.svg"
                      alt="alt"
                    >
                    <p>Amazing free 24/7 support</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-guage2.svg"
                      alt="alt"
                    >
                    <p>Lightning fast message delivery,</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-rocket.svg"
                      alt="alt"
                    >
                    <p>High performance – 4800 messages per minute</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-bar1.svg"
                      alt="alt"
                    >
                    <p>Guaranteed delivery with Tier 1 networks</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-globe.svg"
                      alt="alt"
                    >
                    <p>Send SMS online to all countries globally</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-note.svg"
                      alt="alt"
                    >
                    <p>Customize sender ID</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-pie1.svg"
                      alt="alt"
                    >
                    <p>Real-time message status monitoring</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-chat1.svg"
                      alt="alt"
                    >
                    <p>Secure 256-bit SSL</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-chat1.svg"
                      alt="alt"
                    >
                    <p>Long 1224 character limit</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-monitor.svg"
                      alt="alt"
                    >
                    <p>Access to our SMS portal</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-xls.svg"
                      alt="alt"
                    >
                    <p>Upload from CSV or Excel file for bulk SMS campaigns</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-people.svg"
                      alt="alt"
                    >
                    <p>We automatically handle opt-outs for marketing campaigns</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-person.svg"
                      alt="alt"
                    >
                    <p>Free fixed number</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-back.svg"
                      alt="alt"
                    >
                    <p>Free replies</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-setting1.svg"
                      alt="alt"
                    >
                    <p>No setup fees</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-check.svg"
                      alt="alt"
                    >
                    <p>No hidden charges</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-monitor.svg"
                      alt="alt"
                    >
                    <p>Access to our online SMS software</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-bell.svg"
                      alt="alt"
                    >
                    <p>Free low credit warning</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-card.svg"
                      alt="alt"
                    >
                    <p>Optional auto-topup</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-up.svg"
                      alt="alt"
                    >
                    <p>Free delivery reports</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-setting2.svg"
                      alt="alt"
                    >
                    <p>Free customization</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-play.svg"
                      alt="alt"
                    >
                    <p>No setup required. Be up and running in minutes.</p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src="../../../assets/img/ic-shared-wallet.svg"
                      alt="alt"
                    >
                    <p>Flexible prepaid and postpaid payment options available</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottomQuestionsWrapper">
          <h2>Questions</h2>
          <p>Free accounts and free advice. We can’t wait to see what you create.</p>
          <button
            v-if="isUserLoggedIn"
            class="getSTartedBtn"
            @click="$router.push('/pages/dashboard')"
          >
            Goto dashboard
          </button>
          <button
            v-if="!isUserLoggedIn"
            class="getSTartedBtn"
            @click="$router.push('/register')"
          >
            Get Started Now!
          </button>
        </div>
      </div>
    </div>
    <public-footer />
  </div>
</template>

<script>
  export default {
    name: 'SMSServicePage',

    components: {
      PublicHeader: () => import('../components/PublicHeader'),
      PublicFooter: () => import('../components/PublicFooter'),
    },

    data: () => ({
      isUserLoggedIn: false,
    }),
    computed: {},
    created () {
      window.scrollTo(0, 0)
      this.checkIfUserLoggedIn()
    },
    methods: {
      checkIfUserLoggedIn () {
        const username = localStorage.getItem('username')
        if (username && username.length) {
          this.isUserLoggedIn = true
        }
      },
      redirectToPage (path) {
        window.location = window.location.origin + path
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css";
@import '../../../assets/bootstrap.min.css';
@import '../../../assets/now-ui-kit.css';
.page-header-image {
  background-image: radial-gradient(circle 1693px at 100% 100%,#47b3f9 0,#3fd7ff 23%,#00e3ff 41%,#1ac6fb 62%,#b54fff 100%);
}
.page-header:before {
  background-color: rgba(8, 8, 8, 0);
}
.page-header.page-header-small {
  max-height: unset!important;
  // height: 600px!important;
  position: relative;
}
.getSTartedBtn {
  background-color: rgb(0, 76, 255);
  padding: 8px 35px;
  color: white;
  box-shadow: 5px 5px 25px rgba(0,0,0,0.2);
  margin-bottom: 40px;
  margin-top: 40px;
  &:hover {
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.054);
  }
}
.landingBackgroundGraphic {
  position: absolute;
  right: 0;
  bottom: 10%;
  width: 600px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.overviewSectionWrapper {
  .overviewSectionHeaderContents {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 15px 45px;
    .sub1 {
      margin-top: 1rem;
      font-size: 1.5rem;
      color: rgb(155, 155, 155);
    }
    .sub2 {
      margin-top: 1.5rem;
      font-size: 1.6rem;
      color: rgb(155, 155, 155);
      margin-bottom: 2rem;
    }
  }
  .overViewCard {
    margin: 12px 25px;
    padding: 30px 40px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    box-shadow: 3px 3px 120px rgba(0, 0, 0, 0.081);
    .textSection {
      margin-left: 1.7rem;
      h4 {
        font-weight: 500;
      }
      p {
        font-size: 1rem;
        color: rgb(155, 155, 155);
        line-height: 24px;
      }
    }
  }
}
  .smsGatewayWrapper {
    padding: 3rem 1rem;
    background-color: #F3F8FD;
    .smsGatewayWrapperInnerWrapper {
      text-align: center;
    }
  }
  .smsGatewayInfo {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .textSection {
      padding-left: 3.5rem;
      .descWrapper {
        p {
          font-size: 1.2rem;
          margin-top: -5px;
          margin-bottom: 4rem;
          color: rgb(155, 155, 155);
        }
      }
    }
  }
  .whyUseWrapper {
    padding: 9rem 0;
    text-align: center;
    .useSub1 {
      h3 {
        font-weight: 600;
      }
      p {
        font-size: 1.2rem;
          margin-top: -5px;
          margin-bottom: 4rem;
          color: rgb(155, 155, 155);
      }
    }
    .useSub2 {
      img {
        height: 60px;
      }
      h3 {
        margin-top: 2rem;
        font-size: 1.7rem;
        font-weight: 600;
      }
      p {
        font-size: 1.2rem;
          margin-top: -5px;
          margin-bottom: 4rem;
          color: rgb(155, 155, 155);
      }
    }
  }
  .standerdFeatureWrapper {
       background-image: radial-gradient(circle 1693px at 100% 100%,#47b3f9 0,#3fd7ff 23%,#00e3ff 41%,#1ac6fb 62%,#b54fff 100%);
      min-height: 800px;
      margin-top: 3rem;
      .standerdFeatureHeaderImageWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-top: -3rem;
          border: 8px solid white;
          border-radius: 100PX;
        }
      }
      h4 {
        width: 100%;
        text-align: center;
        font-size: 1.8rem;
        color: white;
        margin-top: 15px;
        margin-bottom: 20px;
      }
      .standerdFeatureInnerWrapper {
        width: 90%;
        box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        border-radius: 10px;
        padding: 50px 55px;
        background-color: white;
        margin-top: 35px;
        .col-md-6 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100px;
          text-align: left;
          img {
            width: 30px;
          }
          p {
            margin: 0;
            margin-left: 2rem;
            font-size: 1rem;
          }
        }
      }
    }
    .bottomQuestionsWrapper {
      margin-top: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      .getSTartedBtn {
          background-color: rgb(0, 76, 255);
          padding: 8px 35px;
          color: white;
          box-shadow: 5px 5px 25px rgba(0,0,0,0.2);
          margin-bottom: 40px;
          margin-top: 10px;
          &:hover {
            box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.054);
          }
        }
    }
</style>
